<template>
   <div>
     <!-- Branding Section -->
     <base-header
         class="pb-6 pt-5 content__title content__title--dashboard"
         type="base"
     ></base-header>
     <div>
        <div id="main-dashboard" class="container-fluid mt--6">

          <!-- Welcome Panel -->
          <div class="row align-content-around">
            <!-- News Card -->
            <div class="col-md-6">
              <card>
                <!-- Card body -->
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                      <i class="fa fa-newspaper"/>
                    </div>
                  </div>
                  <div class="col ml--2 mb-3">
                    <h4 class="mb-0 mt-3">
                      <a href="#!">Latest news</a>
                    </h4>
                    <p class="text-sm text-muted mb-0">[OCT 2023] We are excited to launch our <badge rounded type="base" size="md">BETA</badge> version of our App  </p>
                  </div>
                </div>
              </card>
            </div>

            <!-- User card -->
            <div class="col-md-6">
              <card>
                <!-- Card body -->
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                      <i class="fa fa-user"/>
                    </div>
                  </div>
                  <div class="col ml--2">
                    <p class="text-sm text-muted mt-1 mb--1">Welcome back</p>
                    <h4 class="text-muted mb-2">{{ user.name }}</h4>
                    <span v-if="isGuestUser()" class="text-warning"> ● </span>
                    <span v-else-if="isFreemiumUser() || isPremiumUser()" class="text-success"> ● </span>
                    <span v-else class="text-danger"> ● </span>
                    <small>{{ isPremiumUser() ? "Premium member " :  isFreemiumUser() ? "Freemium member"  : "Free member"}} </small>
                  </div>
                  <div class="col-auto mt-3" style="margin-left: 4rem">
                    <base-button type="primary"
                                 :block="true"
                                 size="sm"
                                 @click="goToMyTickets"
                    >
                      <i class="fa fa-ticket-alt mr-3"/>
                      My Tickets
                    </base-button>

                    <base-button type="primary"
                                 :block="true"
                                 size="sm"
                                 @click="goToScanTickets"
                                 outline
                                 class="ml-2"
                                 v-if="!isFreeUser()"
                    >
                      <i class="fa fa-qrcode mr-2" />
                      Scan Tickets
                    </base-button>

                  </div>
                  <div class="col-auto">

                  </div>
                </div>
              </card>
            </div>
          </div>
          <!-- Quick Card Stats -->
          <div class="row mb--1">
            <div class="col-md-12 align-items-center ">
              <card
                  class="no-border-card"
                  body-classes="px-0 pb-1"
                  footer-classes="pb-2"
              >
                <template slot="header">
                  <div class="row">
                    <div class="col-6">
                      <h3 class="mb-0">
                        My Recent Events
                      </h3>
                    </div>
                  </div>
                </template>

                <div class="pt-0">
                  <el-table
                      class="table-responsive align-items-center table-flush mt--4"
                      header-row-class-name="thead-light"
                      :data="items"
                  >
                    <!-- Load Spinner -->
                    <div slot="empty" v-if="loading">
                      <img src="/img/loading.gif" style="height: 100px; width: 100px" />
                    </div>

                    <el-table-column label="Name"
                        min-width="220px"
                        max-width="240px"
                        prop="name"
                    >
                      <template v-slot="{ row }">
                        <a
                            type="text"
                            @click="viewItem(row)"
                            class="table-action"
                            data-toggle="tooltip"
                            style="cursor: pointer"
                        >
                          <strong class="text-black-50">{{ row.name }}</strong>
                        </a>
                      </template>
                    </el-table-column>

                    <el-table-column label="Type"
                                     prop="event_type"
                                     min-width="120px"
                                     align="left">
                      <template v-slot="{ row }">
                        <badge class="badge-dot mr-4" type="">
                          <i :class="`bg-${getItemType(row.event_type)}`"></i>
                          <span class="status">
                            {{ lodash_.upperFirst(row.event_type) }}
                          </span>
                        </badge>
                      </template>
                    </el-table-column>

                    <el-table-column label="Attendees"
                                     prop="total_attendees"
                                     min-width="145px"
                                     align="left"
                    >
                      <template v-slot="{ row }">
                        <div class="avatar-group">
                            <a
                                v-if="row.total_attendees > 2"
                                href="#"
                                class="avatar avatar-sm rounded-circle"
                                data-toggle="tooltip"
                                data-original-title="Ryan Tompson"
                            >
                              <img alt="Image placeholder" src="/img/theme/team-1.jpg" />
                            </a>
                            <a
                                v-if="row.total_attendees > 1"
                                href="#"
                                class="avatar avatar-sm rounded-circle"
                                data-toggle="tooltip"
                                data-original-title="Romina Hadid"
                            >
                              <img alt="Image placeholder" src="/img/theme/team-2.jpg" />
                            </a>
                            <a
                                v-if="row.total_attendees > 0"
                                href="#"
                                class="avatar avatar-sm rounded-circle"
                                data-toggle="tooltip"
                                data-original-title="Alexander Smith"
                            >
                              <img alt="Image placeholder" src="/img/theme/team-3.jpg" />
                            </a>
                            <a
                                v-if="row.total_attendees > 3"
                                href="#"
                                class="avatar avatar-sm rounded-circle"
                                style="bottom: 10px"
                                data-toggle="tooltip"
                                data-original-title="more"
                            >
                              {{ getAttendeesCount(row) }}

                            </a>
                            <a
                                v-else-if="row.total_attendees == 0"
                                href="#"
                                class="avatar avatar-sm rounded-circle text-center"
                                data-toggle="tooltip"
                                data-original-title="more"
                            >
                              {{ getAttendeesCount(row) }}

                            </a>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column label="Updated at"
                                     min-width="170px"
                                     prop="updated_at"
                                     sortable
                    >
                      <template v-slot="{ row }">
                        <small class="text-muted text-uppercase text-black text-wrap">
                          {{row.updated_at | updatedFriendlyTime }}
                        </small>
                      </template>
                    </el-table-column>

                    <el-table-column label="Created at"
                                     min-width="170px"
                                     prop="created_at"
                                     sortable
                    >
                      <template v-slot="{ row }">
                        <small class="text-muted text-uppercase text-black-60 ml-3">
                          {{row.created_at | humanTime }}
                        </small>
                      </template>
                    </el-table-column>

                    <el-table-column label="Actions"
                                     min-width="125px"
                                     align="left">
                      <template v-slot="scope">
                        <div class="table-actions ml-3">
                          <el-tooltip content="Preview event" placement="top">
                            <a
                                type="text"
                                @click="viewItem(scope.row)"
                                class="table-action"
                                data-toggle="tooltip"
                                style="cursor: pointer"
                            >
                              <i class="far fa-eye text-base"></i>
                            </a>
                          </el-tooltip>

                          <el-tooltip content="Edit event" placement="top">
                            <a
                                type="text"
                                @click="editItem(scope.row)"
                                class="table-action"
                                data-toggle="tooltip"
                                style="cursor: pointer"
                            >
                              <i class="far fa-edit text-base"></i>
                            </a>
                          </el-tooltip>
                        </div>
                      </template>
                    </el-table-column>

                  </el-table>
                </div>
              </card>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-alert dismissible type="primary" class="mb-5">
                <i class="fa fa-chart-pie mr-1"/>
                Insights & Statistics will be available when data (like bookings and traffic) has been generated from your events :-)          </base-alert>
            </div>
          </div>

          <!-- Quick Card Stats -->
          <div class="row">
            <div class="col-md-4 blur-content">
              <base-stats-card title="Events Traffic"
                          type="gradient-orange"
                          sub-title="2,356"
                          icon="ni ni-chart-pie-35">

                <template slot="footer">
                  <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
                  <span class="text-nowrap">Since last month</span>
                </template>
              </base-stats-card>
            </div>
            <div class="col-md-4 blur-content">
              <base-stats-card title="Tickets Sales"
                          type="gradient-green"
                          sub-title="924"
                          icon="ni ni-money-coins">

                <template slot="footer">
                  <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 5.72%</span>
                  <span class="text-nowrap">Since last month</span>
                </template>
              </base-stats-card>

            </div>
            <div class="col-md-4 blur-content">
              <base-stats-card title="Latest Performance"
                          type="gradient-info"
                          sub-title="49,65%"
                          icon="ni ni-chart-bar-32">

                <template slot="footer">
                  <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
                  <span class="text-nowrap">Since last month</span>
                </template>
              </base-stats-card>
            </div>
          </div>

          <!--Charts-->
          <div class="row">
            <div class="col-md-12">
              <div class="card-deck flex-column flex-xl-row">
                <card class="blur-content">
                  <div slot="header" class="row align-items-center">
                    <div class="col">
                      <h6 class="text-uppercase text-muted ls-1 mb-1">Overview</h6>
                      <h5 class="h3 mb-0">Total Revenues</h5>
                    </div>
                  </div>
                  <line-chart
                    :height="350"
                    ref="bigChart"
                    :chart-data="bigLineChart.chartData"
                    :extra-options="bigLineChart.extraOptions"
                  >
                  </line-chart>

                </card>

                <card header-classes="bg-transparent" class="blur-content">
                  <div slot="header" class="row align-items-center">
                    <div class="col">
                      <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                      <h5 class="h3 mb-0">Monthly Bookings</h5>
                    </div>
                  </div>

                  <bar-chart
                    :height="350"
                    ref="barChart"
                    :chart-data="redBarChart.chartData"
                  >
                  </bar-chart>
                </card>

              </div>
              <!-- End charts-->

              <!--End lists-->
            </div>
          </div>

        </div>
     </div>
    </div>
</template>
<script>
  // Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';

  // Components
import BaseProgress from '@/components/BaseProgress';

  // Lists
import {
  Table,
  TableColumn
} from "element-ui";
import swal from "sweetalert2";

// 3rd party packages
import _ from "lodash";
  import organizer from "@/middleware/organizer";

export default {
  components: {
    LineChart,
    BarChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      bigLineChart: {
        chartData: {
          datasets: [
            {
              label: 'Revenue',
              data: [0, 20, 10, 30, 15, 40, 20, 60, 100],
            }
          ],
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [{
            label: 'Tickets',
            data: [225, 420, 630, 522, 670, 590, 300, 420]
          }]
        }
      },
      radioOptions: [
        {value: 'today', label: 'Today'},
        {value: 'lastWeek', label: 'Last Week'},
        {value: 'lastMonth', label: 'Last Month'}
      ],
      buttonRadioGroup: 'today',
      selectedRows: [],
      sort: "-updated_at", 
      pagination: {
        perPage: 3,
        currentPage: 1
      },
      items: [],
      loading: true,
      lodash_: _,
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  created() {
    this.fetchItemList();
  },
  methods: {
    organizer,
    async fetchItemList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
            ...(this.query ? { name: this.query } : {}),
            // ...(this.query ? { category: this.query } : {}),
            // ...(this.query ? { tags: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "category,tags",
        };

        // fire a GET request to load the items
        await this.$store.dispatch("items/list", params).then(() => {
          this.items = this.$store.getters["items/list"];
          this.total = this.$store.getters["items/listTotal"];
        });

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    async deleteItem(id) {
      const confirmation = await swal.fire({
        title: `Delete this item?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        confirmButtonClass: "btn btn-warning",
        cancelButtonClass: "btn btn-primary",
      });

      try {
        if (confirmation.value === true) {
          // if (this.user.roles.name === "member") {
          //   this.$notify({
          //     type: "danger",
          //     message: "Oops, you are not authorized to do this action.",
          //   });
          //   return;
          // }
          // send DELETE request to remove the Event
          await this.$store.dispatch("items/destroy", id).then(
              () => this.fetchItemList() // fetch to refresh the remaining Item list
          );

          this.$notify({
            type: "success",
            message: "Event deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    getAttendeesCount(row) {
      return row.total_attendees > 3
          ? "+" + (row.total_attendees - 3)
          : row.total_attendees;
      },
    getItemType(row) {
      let status;
      switch (row) {
        case "virtual":
        case "hybrid":
          status = "primary";
          break;
        case "physical":
          status = "success";
          break;
        case "tba":
          status = "yellow";
          break;
      }
      return status;
    },
    viewItem(row) {
      this.$router.push({
        name: "Event Preview",
        params: { id: row.id },
      });
    },
    editItem(row) {
      this.$router.push({
        name: "Edit Event",
        params: { id: row.id },
      });
    },
    goToMyTickets() {
      this.$router.push({ path: "/manage/attendees/list-tickets" });
    },
    goToScanTickets() {
      this.$router.push({ path: "/manage/event-scan" });
    },
  }
};
</script>
<style></style>
