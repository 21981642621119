import store from "../store";

export default async function organizer({ next, router }) {
  await store.dispatch("profile/me");
  let me = await store.getters["profile/me"];

  if (store.getters.isAuthenticated && me.roles[0].name==='organizer') {
    return router.push({ path: "/" });
  }

  return next();
}
